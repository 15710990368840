import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { privateRoutes } from '@constants/routes'
import { UserId } from '@api/endpoints'
import { User, UserList } from '@api/endpoints/users'
import { Button } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useQueryGetUsers } from '@hooks/ReactQuery/users/useQueryGetUsers'
import { RouterButton } from '@controls/buttons'
import { AdminPage } from '@layout/AdminPage'
import styles from '../Admin.module.css'

export const UsersPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const getUsers = useQueryGetUsers()

  const isLoading = getUsers.isFetching

  const onEditClick = React.useCallback(
    (userId: UserId) => {
      navigate(generatePath(privateRoutes.UserEdit, { userId: userId.toString() }))
    },
    [navigate]
  )

  const onRefreshClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    void getUsers.refetch()
  }

  const columns: Array<GridColDef<User>> = React.useMemo(
    () => [
      { field: 'userId', headerName: t('pages.users.fields.userId'), minWidth: 200 },
      { field: 'userName', headerName: t('pages.users.fields.username'), minWidth: 200 },
      { field: 'role', headerName: t('pages.users.fields.role'), minWidth: 200 },
      {
        field: 'actions',
        renderCell: (params) => (
          <Button onClick={() => onEditClick(params.row.userId)}>{t('global.buttons.edit')}</Button>
        )
      }
    ],
    [t, onEditClick]
  )

  const rows: UserList = React.useMemo(
    () =>
      getUsers.data
        //?.concat()
        ?.map((v) => ({ id: v.userId, ...v }))
        ?.sort((a, b) => a.userName.localeCompare(b.userName)) ?? [],
    [getUsers.data]
  )

  return (
    <AdminPage>
      <h1>{t('pages.users.title')}</h1>
      <div className={styles.controlsBar}>
        <RouterButton variant="contained" to={privateRoutes.UserNew} disabled={isLoading}>
          {t('global.buttons.new')}
        </RouterButton>
        <Button variant="contained" onClick={onRefreshClick} disabled={isLoading}>
          {t('global.buttons.refresh')}
        </Button>
      </div>
      <div>
        <DataGrid columns={columns} rows={rows} checkboxSelection disableRowSelectionOnClick />
      </div>
    </AdminPage>
  )
}
