import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getUsers, UserList } from '@api/endpoints/users'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetUsersAll } from './queryKeys'

export const useQueryGetUsers = (): UseQueryResult<UserList> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetUsersAll,
    queryFn: getUsers
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
